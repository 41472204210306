<template>
  <div style="display:flex;align-items: center;">
    <el-tooltip placement="bottom" effect="light" :hide-after="100000">
      <div>
        <span>学生登录</span>
        <span style="margin-left:24px;">|</span>
      </div>
      <template slot="content">
        <img style="width: 150px; height: 170px" src="../../../static/images/student_qrcode.png" fit="fill" />
      </template>
    </el-tooltip>
    <el-dropdown @command="handleCommand" v-if="isLogin">
      <span class="el-dropdown-link">
        <div class="login div-avatar">
          <span class="nickname">{{ nickname }}</span>
          <i :class="`mr-16 el-icon-caret-bottom  ${light ? 'btn-lightback' : 'btn-darkback'}`" />
          <el-avatar :size="40" :src="avatar || '/static/images/touxiang.png'" />
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="account">帐号信息</el-dropdown-item>
        <!-- <el-dropdown-item command="account">人员管理</el-dropdown-item> -->
        <!-- <el-dropdown-item command="certificate">我的证书</el-dropdown-item> -->
        <!-- <el-dropdown-item command="enterprise">进入企业版</el-dropdown-item>
      <el-dropdown-item command="school">进入学校版</el-dropdown-item> -->
        <!-- <el-dropdown-item command="student">个人中心</el-dropdown-item> -->
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div v-else style="margin-left:24px;">
      <span class="btn-text-l" @click="handleLogin">老师登录</span>
      <!-- <span class="remark-text ml-8 mr-8">|</span> -->
      <!-- <span class="btn-text-l" @click="handleRegister">注册 </span> -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Account",
  components: {},
  props: {
    light: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("oidc", { isLogin: "oidcIsAuthenticated" }),
    ...mapGetters(["nickname", "avatar"])
  },
  methods: {
    ...mapActions("oidc", {
      signIn: "authenticateOidc",
      signOut: "signOutOidc"
    }),
    async handleCommand(command) {
      if (command === "logout") {
        this.$confirm("您确定要注销当前登录用户吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(_ => {
            this.signOut(); //.then(_ => this.$router.push('/')).catch(_ => this.$router.push('/'));
          })
          .catch(err => {
            console.error(err);
          });
      } else if (command === "account") {
        this.$router.push(`/account`);
      } else if (command === "certificate") {
        this.$router.push(`/certificate`);
      } else if (command === "enterprise") {
        this.$router.push(`/enterprise`);
      } else if (command === "school") {
        this.$router.push(`/school`);
      } else if (command === "student") {
        this.$router.push(`/student`);
      }
    },
    handleLogin() {
      this.signIn({ options: { extraQueryParams: { type: "login" } } });
    },
    handleRegister() {
      this.signIn({ options: { extraQueryParams: { type: "register" } } });
    }
  }
};
</script>

<style>
.nickname {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-left: 24px;
}
.div-avatar {
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .v-sheet--tile,.v-toolbar__content{
  height: 60px !important;
} */
</style>
